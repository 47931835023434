import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { DatePicker, LoadingButton } from "@mui/lab";
import { DateField } from '@mui/x-date-pickers/DateField';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFormation } from "../../redux/actions/formationActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

const mapState = ({ formation }) => ({
  loading: formation.loading,
});

const AddEvent = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  const { loading } = useSelector(mapState);

  const handleEditorChange = (value) => {
    setProduct({ ...product, description: value });
  };

  const handleChange = (e) =>
    setProduct({ ...product, [e.target.name]: e.target.value });

  const loadFile = async (event) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      //const image = await resizeFile(event.target.files[0]);
      setProduct({ ...product, image: event.target.files[0] });
    }
  };

  const redirect = () => navigate("/evenements");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addFormation(product, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Ajouter un événement " />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Titre"
                required
                type="text"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateField']}>
                  <DateField fullWidth label="Date" />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={product.description}
                onChange={handleEditorChange}
                style={{ height: 200 }}
              />
            </Grid>
            <Grid sx={{ marginTop: 5 }} item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="import"
                type="file"
                onChange={loadFile}
                multiple
              />
              <label htmlFor="import">
                <Button
                  startIcon={<CameraAlt />}
                  variant="outlined"
                  component="span"
                >
                  Image de couverture
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                <img id="output" width={200} />
              </Typography>
            </Grid>
            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white", borderRadius: 10 }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default AddEvent;
