import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCKUKh2UlVUkbEGpjPXGi1cZ-28IAHkqxQ",
  authDomain: "apotre-lmk.firebaseapp.com",
  projectId: "apotre-lmk",
  storageBucket: "apotre-lmk.appspot.com",
  messagingSenderId: "314428734133",
  appId: "1:314428734133:web:23a4662598ff7566a21cfc",
  measurementId: "G-QLZTETQW1E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
