import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import EventIcon from "@mui/icons-material/Event";
import PublishIcon from "@mui/icons-material/Publish";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
const iconProps = {
  color: "primary",
};

export const drawerItems = [
  {
    text: "Blog",
    icon: <ArticleIcon {...iconProps} />,
    path: "/blog",
  },
  {
    text: "Evénements",
    icon: <EventIcon {...iconProps} />,
    path: "/evenements",
  },
];

export const secondaryListItems = [
  {
    text: "Publier un article",
    icon: <PublishIcon {...iconProps} />,
    path: "/ajouter-un-article",
  },
  {
    text: "+ Evénement",
    icon: <EventAvailableIcon {...iconProps} />,
    path: "/ajouter-un-evenement",
  },
];
