import Blog from "./views/blog";
import AddArticle from "./views/blog/Add";
import BlogDetails from "./views/blog/Details";
import EditArticle from "./views/blog/Edit";
import Events from "./views/events";
import AddEvent from "./views/events/Add";

const routes = [
  {
    name: "Blog",
    path: "/blog",
    component: <Blog />,
  },
  {
    name: "Blog Details",
    path: "/blog/:articleID",
    component: <BlogDetails />,
  },
  {
    name: "Add Article",
    path: "/ajouter-un-article",
    component: <AddArticle />,
  },
  {
    name: "Edit Article",
    path: "/blog/modifier/:articleID",
    component: <EditArticle />,
  },
  {
    name: "Events",
    path: "/evenements",
    component: <Events />,
  },
  {
    name: "Event Details",
    path: "/evenements/:eventID",
    component: <BlogDetails />,
  },
  {
    name: "Add Event",
    path: "/ajouter-un-evenement",
    component: <AddEvent />,
  },
  {
    name: "Edit Event",
    path: "/evenements/modifier/:eventID",
    component: <EditArticle />,
  },
];

export default routes;
